import { Link } from "react-router-dom"

const Public = () => {

    const content = (
        <section className="public">
            <header>
                <h1>Welcome!</h1>
            </header>
            <main>
                <p>Jobetix is the world's best job ad aggregator.</p>
                <p>Join to receive the latest job ads from many different portals every day!</p>
            </main>
            <footer>
                <Link to="/login">Login</Link>
                <Link to="/register" style={{marginLeft: '1em'}}>Register</Link>
            </footer>
        </section>
    )
    return content
}
export default Public;