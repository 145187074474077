import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;      
  margin-top: 1rem;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Input = styled.input`
    background: #F2F1F9;
    border: none;
    padding: 0.4rem;
`

const Button = styled.button`
  width: 10rem;
  background-color: #e66465;
  border-color: var(--color-primary);
  margin-left: 0.5rem;
  color: whitesmoke;
  @media (max-width: 600px) {
    width: 100%;
    height: 2em;
    margin-top: 0.5em;
    margin-left: 0;
  }
`

const SearchBar = ({ message, onButtonClick }: any) => {
    const [keyword, setKeyword] = useState('');
    return (
        <Wrapper>
            <Input
                key="random1"
                value={keyword}
                placeholder={"enter job title"}
                onChange={(e) => setKeyword(e.target.value)}
            />
            <Button type="button" onClick={() => onButtonClick(keyword)}>Search</Button>
        </Wrapper>
    );
}

export default SearchBar