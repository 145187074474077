import { useState, useEffect } from "react";
import Table from "../../components/AppTable";
import Pagination from "../../components/Pagination";
import { useSelector } from "react-redux";
import {
  selectLoading,
  selectError,
  selectSuccess,
  selectErrorMessage,
  selectSavedJobs,
} from './jobSlice';
import { useGetJobsQuery, useSaveJobMutation } from './jobApiSlice';
import Loader from "../../components/Loader";
import SearchBar from '../../components/SearchBar';
import ButtonComponent from "../../components/Button";
import Modal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Store } from 'react-notifications-component';
import SavedJobs from "./SavedJobs";

Modal.setAppElement('#root');

interface Job {
  city: string;
  created_at: string
  description?: string;
  has_salary: boolean;
  id: number;
  job_status_id: number
  title: string;
  updated_at: string;
  user_id?: number;
  company_name: string;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#0F172A',
  },
};

const getDiffInDays = (date: Date) => {
  const today = new Date();
  const diff = Math.abs(date.getTime() - today.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

const JobList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const [jobTitle, setJobTitle] = useState('');
  const { data }: any = useGetJobsQuery({ page: currentPage - 1, size: pageSize, title: jobTitle });
  const [selectedJobId, setSelectedJobId] = useState(undefined);
  const [modalIsOpen, setIsOpen] = useState(false);
  let selectedJob: Job | undefined;
  if (data) selectedJob = data.jobs.records.filter((e: any) => e.id === selectedJobId)[0];

  // get jobs selectors
  const isLoading = useSelector(selectLoading);
  const isSuccess = useSelector(selectSuccess);
  const isError = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  const [saveJobRequest, saveJobRequestStatus] = useSaveJobMutation();

  useEffect(() => {
    if (saveJobRequestStatus.isSuccess) {
      Store.addNotification({
        title: "Wonderful!",
        message: `Saving ${selectedJob?.title} completed successfully`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      })
    }
  }, [saveJobRequestStatus.isSuccess]);

  useEffect(() => {
    setCurrentPage(1);
  }, [jobTitle]);

  useEffect(() => {
    if (saveJobRequestStatus.isError) {
      Store.addNotification({
        title: "Failed",
        message: `Saving ${selectedJob?.title} failed`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      })
    }
  }, [saveJobRequestStatus.isError]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    saveJobRequest({ jobId: selectedJobId });
    Store.addNotification({
      title: `Saving ${selectedJob?.title}`,
      message: "teodosii@react-notifications-component",
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 500,
        onScreen: true
      }
    })
    closeModal();
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const columns = [
    {
      Header: "Job title",
      Cell: (props: any): any => {
        return (
          <a href={props.row.original.job_url} target="_blank">
            <p className="title">{props.row.original.title}</p>
          </a>
        );
      }
    },
    {
      Header: "Company",
      Cell: (props: any): any => {
        return (
          <a href={props.row.original.company_url}>
            <p className="title">{props.row.original.company_name}</p>
          </a>
        );
      }
    },
    {
      Header: "Days old",
      accessor: "created_at",
      Cell: ({ cell: { value } }: { cell: { value: Date } }) => <>{`${getDiffInDays(new Date(value))}`}</>
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        return (<>{<ButtonComponent type={'primary'} text={'Save'} jobId={value} setSelectedJobId={setSelectedJobId} openModal={setIsOpen} />}</>);
      }
    },
  ];

  const onJobSearchClicked = (input: string) => {
    setJobTitle(input);
  }

  const getTable = (tableData: any) => {
    return (
      <>
        <div style={{ minHeight: '400px', marginTop: '1rem' }}>
          <Table
            columns={columns}
            data={tableData.jobs.records}
            isLoading={isLoading}
          />
        </div>
        <Pagination
          totalItems={tableData.jobs.totalItems}
          totalPages={tableData.jobs.totalPages}
          currentPage={tableData.jobs.currentPage + 1}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={pageSize}
        />
      </>
    )
  }

  const content =
    <div style={{ margin: '2rem' }}>
      <span>{'Find your new job'}</span>
      <div style={{ marginBottom: '1rem' }}>
        <SearchBar onButtonClick={onJobSearchClicked} />
      </div>
      {isSuccess && <span id='jobSearchSpan'>{`${data.jobs.totalItems} jobs match your search`}</span>}
      {isLoading && <Loader />}
      {isSuccess && getTable(data)}
      {isError && <>{errorMessage}</>}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} style={{ color: 'white', position: 'absolute', top: 0, right: '10px', backgroundColor: '#0F172A', border: 'none' }}>x</button>
        <Form onSubmit={submitHandler}>
          <Form.Label style={{ color: 'var(--color-primary)', fontSize: '18px' }}>Company</Form.Label>
          <Form.Control type="string" value={selectedJob?.company_name} readOnly />
          <Form.Label style={{ color: 'var(--color-primary)', fontSize: '18px' }}>Location</Form.Label>
          <Form.Control type="string" value={'Greater Copenhagen'} readOnly />
          <Form.Label style={{ color: 'var(--color-primary)', fontSize: '18px' }}>Created at</Form.Label>
          <Form.Control type="string" value={new Date(selectedJob?.created_at || '2022-01-01').toDateString() || 'No date'} readOnly />
          <div style={{ marginTop: '20px' }}>
            <Button type="submit" style={{ width: '100%' }}>Save</Button>
          </div>
        </Form>
      </Modal>
    </div>

  return content;
};

export default JobList;
