import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Public from './components/Public';
import Login from './features/auth/Login';
import Welcome from './features/auth/Welcome';
import UsersList from './features/users/UsersList';
import PersistLogin from './features/auth/PersistLogin';
import JobTable from './features/jobs/JobTable';
import About from './features/about/About';
import SavedJobs from './features/jobs/SavedJobs';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />
        <Route element={<PersistLogin />} >
          {/* protected routes */}
          <Route path="welcome" element={<Welcome />} />
          <Route path="userslist" element={<UsersList />} />
          <Route path="jobs" element={<JobTable />} />
          <Route path="about" element={<About />} />
          <Route path="saved-jobs" element={<SavedJobs />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App;