import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentToken, setLoggedIn } from "./authSlice"

const PersistLogin = (): JSX.Element | null => {

    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)

    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation();

    const dispatch = useDispatch();

    useEffect((): any => {
        if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode  
            const verifyRefreshToken = async () => {
                try {
                    // console.log('verify refresh token...');
                    //const response = 
                    await refresh(null)
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    console.error(err)
                }
            }
            if (!token && persist) verifyRefreshToken();
            if (!token && !persist) dispatch(setLoggedIn(true));
        }
        return () => effectRan.current = true
        // eslint-disable-next-line
    }, [])

    let content = null;
    if (!persist) { // persist: no
        // console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        // console.log('loading')
        content = <p>Loading...</p>
    } else if (isError) { //persist: yes, token: no
        content = (
            <p className='errmsg'>
                {JSON.stringify(error)}
                <Link to="/login">Please login again</Link>.
            </p>
        )
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        content = <Outlet />
    }

    return content;
}
export default PersistLogin;