import { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from 'styled-components';
import { useGetSavedJobsQuery, useDeleteSavedJobMutation } from "./jobApiSlice";
import { selectSavedJobs, selectSavedJobsLoading } from "./jobSlice";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  @media (max-width: 1600px) {
    justify-content: center;
  }
`

const fallAway = keyframes`
0% {
  transform: rotateZ(0deg);
  top: 0;
  opacity: 1;
}
15% {
  transform: rotateZ(-15deg);
  opacity: 0.8;
}
30% {
  transform: rotateZ(15deg);
  opacity: 0.6;
}
45% {
  transform: rotateZ(-15deg);
  opacity: 0.5;
}
60% {
  transform: rotateZ(15deg);
  opacity: 0.4;
}
75% {
  transform: rotateZ(-15deg);
  opacity: 0.3;
}
90% {
  transform: rotateZ(15deg);
  opacity: 0.2;
}
100% {
  // top: 300px;
  transform: rotateZ(-5deg);
  opacity: 0;
}
`;

const fallAwayAnimation = (props: any) =>
  css`
    ${fallAway} forwards 2s ease-out 1;
  `

const CardStyle = styled.div<{ backgroundColor: string; showAnimation: boolean; }>`
  display: flex;
  flex-direction: column;
  margin: 20px;  
  padding: 20px;  
  width: 350px;  
  min-height: 200px;  
  border-radius: 10px;  
  box-shadow: 0px 5px 10px #DCD7C9;  
  color: #D49B54;
  background-image: ${p => `linear-gradient(45deg, ${p.backgroundColor}, #1B2430)`};
  animation: ${p => p.showAnimation ? fallAwayAnimation : null};
`

interface CardProsInt {
  jobId: number;
  title: string;
  companyName: string;
  createdAt: string;
  color: string;
  jobUrl: string | undefined;
  deleteJob: (jobId: number) => void;
}

//TODO Card can be in components...
const Card = (props: CardProsInt) => {
  const { jobId, title, companyName, createdAt, color, jobUrl, deleteJob } = props;
  const [showAnimation, setShowAnimation] = useState(false);

  const onDeleteHandler = () => {
    setShowAnimation(true);
    setTimeout(() => {
      deleteJob(jobId);
    }, 1500);
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  }

  return (
    <CardStyle backgroundColor={color} showAnimation={showAnimation}>
      <div style={{ height: '1em', width: '1em', alignSelf: 'flex-end', marginBottom: 10, }} onClick={onDeleteHandler}>{deleteSvg()}</div>
      {
        title &&
        <a href={jobUrl} target="_blank" rel="noreferrer">{title}</a>
      }
      {
        companyName &&
        <div style={{ fontSize: '0.8em', color: 'palevioletred' }}>
          <p>{companyName}</p>
        </div>
      }
      {
        createdAt &&
        <div style={{ fontSize: '0.8em', color: '#A27B5C', }}>
          {`Created: ${createdAt}`}
        </div>
      }
    </CardStyle>
  )
}

const SavedJobs = () => {
  useGetSavedJobsQuery({});
  const savedJobsData = useSelector(selectSavedJobs);
  const isLoadingSavedJobs = useSelector(selectSavedJobsLoading);
  const [deleteSavedJob] = useDeleteSavedJobMutation();

  const colors = ['#16213E', '#0F3460', '#533483'];
  let colorIndex = -1;

  const deleteJob = (jobId: number) => {
    deleteSavedJob(jobId);
  }

  if (isLoadingSavedJobs) return <div>Loading...</div>
  if (!savedJobsData) return <div>Missing post!</div>
  if (savedJobsData && savedJobsData.length === 0) return <div>No data. You can save jobs from the table by clicking the 'Save' button.</div>

  const content =
    <>
      <h3 style={{textAlign: 'center', padding: '1em', color: 'palevioletred' }}>My Saved Jobs</h3>
      <CardWrapper>
        {
          savedJobsData?.map((item, idx) => {
            colorIndex = colorIndex < colors.length - 1 ? colorIndex + 1 : 0;
            return (
              <Card
                jobId={item.id}
                jobUrl={item.job_url ? item.job_url : ''}
                title={item.title}
                companyName={item.company_name}
                createdAt={new Date(item.created_at).toLocaleDateString()}
                color={colors[colorIndex]}
                key={idx}
                deleteJob={deleteJob}
              />
            )
          })
        }
      </CardWrapper>
    </>

  return content;
};

export default SavedJobs;

//TODO: Put it into a component
const deleteSvg = () => {
  return (<svg viewBox="0 0 512 512">
    <path fill='#494F55' d="M334.885,98.803c-1.182,0-2.381-0.142-3.579-0.439c-7.951-1.97-12.801-10.013-10.83-17.965
 l5.66-22.846l-99.892-24.752l-5.662,22.846c-1.97,7.951-10.021,12.801-17.965,10.83c-7.951-1.97-12.801-10.014-10.83-17.965
 l9.229-37.244c1.97-7.952,10.02-12.8,17.965-10.83L347.67,32.325c3.818,0.946,7.105,3.369,9.136,6.739
 c2.031,3.37,2.64,7.408,1.694,11.226l-9.229,37.245C347.597,94.288,341.542,98.803,334.885,98.803z"/>
    <g>
      <polygon fill="none" points="177.957,497.166 111.947,497.166 85.536,153.586 177.957,153.586 	" />
      <polygon fill="none" points="361.805,497.166 295.795,497.166 295.795,153.586 388.217,153.586 	" />
    </g>
    <path fill="#494F55" d="M445.095,146.789L74.039,54.848c-7.945-1.971-15.995,2.876-17.965,10.83
 c-1.971,7.952,2.878,15.995,10.83,17.965l222.414,55.11h-111.36h-6.349H85.535c-4.131,0-8.075,1.724-10.883,4.754
 c-2.808,3.032-4.223,7.096-3.907,11.217l26.412,343.581c0.593,7.728,7.038,13.696,14.79,13.696h66.011h46.21
 c8.191,0,14.833-6.642,14.833-14.833c0-8.191-6.642-14.833-14.833-14.833h-31.377V168.419h88.169v328.747
 c0,8.191,6.642,14.833,14.833,14.833h66.01c7.752,0,14.196-5.967,14.79-13.696l25.491-331.609l35.874,8.89
 c1.199,0.297,2.397,0.439,3.579,0.439c6.657,0,12.712-4.515,14.385-11.269C457.897,156.802,453.048,148.759,445.095,146.789z
  M101.552,168.419h61.573v313.914h-37.442L101.552,168.419z M348.068,482.333h-37.439V168.419H372.2L348.068,482.333z"/>
  </svg>);
}