import { useGetUsersQuery } from "./usersApiSlice"
import { Link } from "react-router-dom";

const UsersList = (): JSX.Element | null => {
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }: any = useGetUsersQuery(null);

    let content = null;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        content = (
            <section className="users">
                <h1>Users List</h1>
                <ul>
                    {data.data.users.map((user: any, i: any) => {
                        return <li key={i}>{user.email}</li>
                    })}
                </ul>
                <Link to="/welcome">Back to Welcome</Link>
            </section>
        )
    } else if (isError) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    return content
}
export default UsersList