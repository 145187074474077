import { useMemo } from "react";
import { useTable } from "react-table";
import Loader from './Loader';
import BTable from 'react-bootstrap/Table';

interface AppTableProps {
  columns: any;
  data: any;
  isLoading: boolean;
}

const AppTable = ({ columns, data, isLoading }: AppTableProps) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: columnData,
    data: rowData
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BTable striped bordered hover variant="dark" size="sm" {...getTableProps()} responsive>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </BTable>
        </>
      )}
    </>
  );
};

export default AppTable;