import { Link } from "react-router-dom"

const Welcome = () => {
    const content = (
        <>
            <section className="welcome">
                <h1>Welcome!</h1>
                <Link to="/userslist">Go to the Users List</Link>
                <Link id={"jobsList"} to="/jobs">Go to the Jobs Table</Link>
            </section>
        </>
    )
    return content
}
export default Welcome;