// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

function BasicExample() {
  return (
    <Navbar collapseOnSelect expand="sm">
      <Navbar.Brand>
          <img style={{ maxWidth: 130, maxHeight: 130, marginLeft: '1em' }} src="atr.png" alt="Girl in a jacket" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="me-auto" style={{marginLeft: '1em'}}>
          <Nav.Link href="/jobs">Find</Nav.Link>
          <Nav.Link href="/saved-jobs">Saved Jobs</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          {/* TODO: implement logout */}
          <Nav.Link href="/logout">Logout</Nav.Link>
          {/* <NavDropdown title="Settings" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Logout</NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default BasicExample;