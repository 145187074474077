import React, { useState, useEffect } from "react";

const styles = {
    paginationStyle: {
        margin: '30px 35px 20px',
        display: 'flex',
        flexDirection: 'column' as const,
        textAlign: 'right' as const,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pageInfo: {
        color: '#a0a3bd',
        fontSize: '0.874em',
        letteSpacing: '0.5px'
    },
    pageButtons: {
        display: 'flex'
    },
    pageBtn: {
        border: '1px solid  #a0a3bd',
        color: '#a0a3bd',
        borderRadius: '5px',
        margin: '5px',
        width: '35px',
        height: '35px',
        fontWeight: 'normal',
        fontSize: '15px'
    },
    activeBtn: {
        border: '1px solid white',
        color: 'white',
        backgroundColor: 'transparent'
    },
    disabledPageBtn: {
        backgroundColor: '#a0a3bd',
        cursor: 'not-allowed',
        opacity: '0.5',
    }
}

const Pagination = ({ totalItems, pageChangeHandler, totalPages, rowsPerPage, currentPage }: any) => {

    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    // Onclick handlers for the butons
    const onNextPage = () => pageChangeHandler(currentPage + 1);
    const onPrevPage = () => pageChangeHandler(currentPage - 1);
    const onPageSelect = (pageNo: any) => {
        if (pageNo === '...') {
            return;
        } else {
            pageChangeHandler(pageNo);
        }
    }
    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
        if (currentPage === totalPages) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [totalPages, currentPage]);

    return (
        <>
            {totalPages > 1 ? (
                <div style={styles.paginationStyle}>
                    <div style={styles.pageInfo}>
                        Showing {(currentPage - 1) * rowsPerPage + 1} - {currentPage === totalPages ? totalItems : ((currentPage - 1) * rowsPerPage) + rowsPerPage} of {totalItems}
                    </div>
                    <div style={styles.pageButtons}>
                        <button
                            onClick={onPrevPage}
                            disabled={!canGoBack}
                            style={styles.pageBtn}
                        >
                            &#8249;
                        </button>
                        {paginationAlgorithm(currentPage, totalPages)?.items.map((a: any) => {
                            return (
                            <button
                                key={a}
                                onClick={() => onPageSelect(a)}
                                style={{ ...styles.pageBtn, ...(a === currentPage && styles.activeBtn) }}
                            >
                                {a}
                            </button>
                            )
                        })}
                        <button
                            onClick={onNextPage}
                            disabled={!canGoNext}
                            style={styles.pageBtn}
                        >
                            &#8250;
                        </button>
                    </div>
                </div>
            ) : null
            }
        </>
    );
};

export default Pagination;

const paginationAlgorithm = (current: any, max: any) => {
    if (!current || !max) return null;
    let items: any = []
    let prev = current === 1 ? null : current - 1,
        next = current === max ? null : current + 1;

    items.push(1);

    if (current === 1 && max === 1) return { current, prev, next, items }
    if (current > 4) items.push('…')

    let r = 1, r1 = current - r, r2 = current + r

    for (let i = r1 > 1 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i)

    if (r2 + 1 < max) items.push('...')
    if (r2 < max) items.push(max)

    return { current, prev, next, items }
}