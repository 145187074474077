import { apiSlice } from "../../app/api/apiSlice"

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: { query: (arg0: { query: (() => string) | (() => string); keepUnusedDataFor: number }) => any }) => ({
        getUsers: builder.query({
            query: () => '/users',
            keepUnusedDataFor: 5,
        }),
        getMe: builder.query({
            query: () => '/me',
            keepUnusedDataFor: 5,
        })
    })
})

export const { useGetUsersQuery, useGetMeQuery } = usersApiSlice