import Button from 'react-bootstrap/Button';

export function ButtonComponent({ type, text, jobId, setSelectedJobId, openModal }: any) {

    const onClick = () => {
        setSelectedJobId(jobId);
        openModal(true);
    }

    switch (type) {
        case 'primary':
            return <Button variant="outline-primary" onClick={onClick}>{text}</Button>
        case 'secondary':
            return <Button variant="outline-secondary" onClick={onClick}>{text}</Button>
        default:
            return <Button variant="outline-primary" onClick={onClick}>{text}</Button>
    }
}

export default ButtonComponent;