import styled from 'styled-components';

const Wrapper = styled.div`

`

const Main = styled.div`
    padding: 0em 0 6em 0;
    color: palevioletred;
    @media (max-width: 736px) {
        padding: 0em 0 4em 0;
    }
`;

const Inner = styled.div`
    width: 100%;
	max-width: 68em;
	margin: 0 auto;		
    padding: 0 2.5em;
    @media (max-width: 736px) {
        padding: 0 1.25em;
    }
`

const Header = styled.h1`
    margin: 1em 0 0.5em 0;
    text-align: center;
`

const ImageContainer = styled.div`
    margin: 0 0 1em 0;
    @media (max-width: 736px) {
        margin: 0 0 2em 0;
    }
`

const Image = styled.img`
    border-radius: 4px;
    filter: grayscale(70%);
	display: block;
    width: 100%;
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    @media (min-width: 600px) {
        flex-direction: row;

    }
`

const Paragraph = styled.p`
    width: 100%;
    max-width: 50ch;
`

const About = (): JSX.Element | null => {
    return (
        <Wrapper>
            <Main>
                <Inner>
                    <Header>About the project</Header>
                    <ImageContainer>
                        <Image src="images/rome_photo.png" alt="" />
                    </ImageContainer>
                    <TextWrapper>
                        <Paragraph>This website was created in Autumn 2022 as my exam project for the Full Stack Web Development course. Througout the course, I gained valuable knowledge of various technologies used in web development. The frontend was developed using React.</Paragraph>
                        <Paragraph>The idea is to create a job ad aggregator that offers a list of job ads from different sources. Moreover, it allows the user to save job ads. Behind the scenes, it utilizes a web scraper to fetch data from the largest online job portal in Denmark, jobindex.dk. More such sources may be added in the future.</Paragraph>
                    </TextWrapper>
                </Inner>
            </Main>
        </Wrapper>
    );
}

export default About;