import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux'
import { selectLoggedIn } from "../features/auth/authSlice";
import NavBar from "./NavBar";

const Layout = () => {
    const loggedIn = useSelector(selectLoggedIn);
    
    return (loggedIn ?
        <div>
            <NavBar />
            <Outlet />
        </div> :
        <Outlet />
    );
}

export default Layout