import { apiSlice } from "../../app/api/apiSlice";
import * as actions from "./jobSlice";

interface Job {
    city: string;
    created_at: string
    description?: string;
    has_salary: boolean;
    id: number;
    job_status_id: number
    title: string;
    updated_at: string;
    user_id?: number;
    company_name: string;
}

export const jobApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getJobs: builder.query({
            query: (arg) => {
                const { page, size, title } = arg;
                return {
                    url: '/jobs',
                    params: { page, size, title },
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    dispatch(actions.getJobsRequest())
                    await queryFulfilled;
                    dispatch(actions.getJobsRequestSucceeded())
                } catch (err: any) {
                    dispatch(actions.getJobsRequestFailed({ err }))
                    console.log(err)
                }
            },
        }),
        saveJob: builder.mutation({
            query: body => ({
                url: '/jobs/save-job',
                method: 'POST',
                body
            })
        }),
        getSavedJobs: builder.query<Job[], {}>({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({ url: `/jobs/saved-jobs`, method: 'GET' }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { result: Job[] }, meta, arg) => {
                return response.result;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (
                response: { status: string | number },
                meta,
                arg
            ) => response.status,
            // The 2nd parameter is the destructured `QueryLifecycleApi`
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                try {
                    dispatch(actions.fetchSavedJobsRequest());
                    const response = await queryFulfilled;
                    dispatch(actions.fetchSavedJobsSucceeded(response.data));
                } catch (err: any) {
                    dispatch(actions.fetchSavedJobsFailed())
                    console.log('fetch saved jobs failed = ', err)
                }
             },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        deleteSavedJob: builder.mutation<{ status: string; result: number; jobId: number }, number>({
            // note: an optional `queryFn` may be used in place of `query`
            query: (jobId) => ({
              url: `/jobs/delete-saved-job`,
              method: 'DELETE',
              body: { jobId },
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { status: string; result: number; jobId: number }, meta, arg) => response,
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (
              response: { status: string | number },
              meta,
              arg
            ) => response.status,
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
              arg,
              { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) {
                try {
                    dispatch(actions.deleteSavedJobRequest());
                    const response = await queryFulfilled;
                    const deletedJobId = response.data.jobId;
                    dispatch(actions.deleteSavedJobSucceeded(deletedJobId))
                } catch (err: any) {
                    dispatch(actions.deleteSavedJobFailed());
                    console.log(err)
                }
            },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
              arg,
              {
                dispatch,
                getState,
                extra,
                requestId,
                cacheEntryRemoved,
                cacheDataLoaded,
                getCacheEntry,
              }
            ) {},
          }),
    })
})

export const {
    useGetJobsQuery,
    useSaveJobMutation,
    useGetSavedJobsQuery,
    useDeleteSavedJobMutation,
} = jobApiSlice;