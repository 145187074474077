import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface AuthState {
    email: string | null;
    accessToken: string | null;
    loggedIn: boolean
}

const initialState: AuthState = {
    email: null,
    accessToken: null,
    loggedIn: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            return { ...state, email: action.payload.email, accessToken: action.payload.access_token, loggedIn: true }
        },
        setLoggedIn: (state, action: PayloadAction<any>) => {
            return { ...state, loggedIn: action.payload }
        },
        logOut: (state) => {
            return { ...state, email: null, accessToken: null, loggedIn: false }
        }
    },
})

export const { setCredentials, setLoggedIn, logOut } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentUser = (state: RootState) => state.auth.email;
export const selectCurrentToken = (state: RootState) => state.auth.accessToken;
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;

export default authSlice.reducer;