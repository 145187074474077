import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/auth/authSlice';

interface User {
    email: string; 
    access_token: string; 
    loggedIn: boolean;
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://job-portal-backend.onrender.com/api' : 'http://localhost:8080/api',
    credentials: 'include',
    prepareHeaders: (headers, { getState }: any) => {
        const { accessToken } = getState().auth;
        // console.log('access token = ', accessToken);
        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`)
        }
        return headers;
    }
})

// wraps the baseQuery
const baseQueryWithReauth = async (args: string | FetchArgs, api: any, extraOptions: {}) => {
    let result = await baseQuery(args, api, extraOptions);
    
    if (result?.error?.status === 401) {
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        if (refreshResult?.data) {
            const user: User = api.getState().auth;            
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut())
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
});
