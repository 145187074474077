import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface Job {
    city: string;
    created_at: string
    description?: string;
    has_salary: boolean;
    id: number;
    job_status_id: number
    title: string;
    updated_at: string;
    user_id?: number;
    company_name: string;
    job_url?: string | undefined;
}

// Define a type for the slice state
interface JobsState {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    queryPageFilter: string;
    queryPageSortBy: [];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
    savedJobs: Job[];
    isLoadingSavedJobs: boolean;
    isErrorSavedJobs: boolean;
    isLoadingDeleteSavedJob: boolean;
    isErrorDeleteSavedJob: boolean;
}

// Define the initial state using that type
const initialState: JobsState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    isLoading: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    savedJobs: [],
    isLoadingSavedJobs: false,
    isErrorSavedJobs: false,
    isLoadingDeleteSavedJob: false,
    isErrorDeleteSavedJob: false,
}

const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        changePage: (state, { payload}) => {
            return { ...state, queryPageIndex: payload }
        },
        changePageSize: (state, { payload }) => {
            return { ...state, queryPageSize: payload }
        },
        changePageSort: (state, { payload }) => {
            return { ...state, queryPageSortBy: payload }
        },
        changePageFilter: (state, { payload }) => {
            return { ...state, queryPageFilter: payload }
        },
        changeTotalCount: (state, { payload }) => {
            return { ...state, totalCount: payload }
        },


        getJobsRequest: (state) => {
            return { ...state, isLoading: true, isError: false, isSuccess: false }
        },
        getJobsRequestSucceeded: (state) => {
            return { ...state, isSuccess: true, isLoading: false, isError: false }
        },
        getJobsRequestFailed: (state, { payload: message }) => {
            return { ...state, isError: true, isSuccess: false, isLoading: false, errorMessage: message }
        },


        fetchSavedJobsRequest: (state) => {
            return { ...state, isErrorSavedJobs: false, isLoadingSavedJobs: true }
        },
        fetchSavedJobsSucceeded: (state, { payload: savedJobs }) => {
            return { ...state, savedJobs, isErrorSavedJobs: false, isLoadingSavedJobs: false }
        },
        fetchSavedJobsFailed: (state) => {
            return { ...state, isErrorSavedJobs: true, isLoadingSavedJobs: false }
        },


        deleteSavedJobRequest: (state) => {
            return { ...state, isLoadingDeleteSavedJob: true, isErrorDeleteSavedJob: false }
        },
        deleteSavedJobSucceeded: (state, { payload: id }) => {
            const newArray = state.savedJobs.filter(savedJob => savedJob.id !== id);
            return { ...state, savedJobs: newArray, isLoadingDeleteSavedJob: false, isErrorDeleteSavedJob: false }
        },
        deleteSavedJobFailed: (state) => {
            return { ...state, isLoadingDeleteSavedJob: false, isErrorDeleteSavedJob: true }
        },
    },
})

export const {
    changePage,
    changePageSize,
    changePageSort,
    changePageFilter,
    changeTotalCount,
    getJobsRequest,
    getJobsRequestSucceeded,
    getJobsRequestFailed,
    fetchSavedJobsRequest,
    fetchSavedJobsSucceeded,
    fetchSavedJobsFailed,
    deleteSavedJobRequest,
    deleteSavedJobSucceeded,
    deleteSavedJobFailed,
} = jobSlice.actions;
export default jobSlice.reducer;
export const selectQueryPageIndex = (state: RootState) => state.jobs.queryPageIndex;
export const selectQueryPageSize = (state: RootState) => state.jobs.queryPageSize;
export const selectTotalCount = (state: RootState) => state.jobs.totalCount;
export const selectQueryPageFilter = (state: RootState) => state.jobs.queryPageFilter;
export const selectQueryPageSortBy = (state: RootState) => state.jobs.queryPageSortBy;
export const selectLoading = (state: RootState) => state.jobs.isLoading;
export const selectSuccess = (state: RootState) => state.jobs.isSuccess;
export const selectError = (state: RootState) => state.jobs.isError;
export const selectErrorMessage = (state: RootState) => state.jobs.errorMessage;

export const selectSavedJobs = (state: RootState) => state.jobs.savedJobs;
export const selectSavedJobsLoading = (state: RootState) => state.jobs.isLoadingSavedJobs;
export const selectSavedJobsError = (state: RootState) => state.jobs.isErrorSavedJobs;

export const selectDeleteSavedJobLoading = (state: RootState) => state.jobs.isLoadingDeleteSavedJob;
export const selectDeleteSavedJobError = (state: RootState) => state.jobs.isErrorDeleteSavedJob;